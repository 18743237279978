import { SVGProps } from "react"

import { ServiceKind } from "@/packages/homebuyerPath/index.types"

import { HomebuyerPathIcon } from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"
import { homebuyerPathIconFromServiceKind } from "@/Components/HomebuyerPath/HomebuyerPathIcons/index"

export const ServiceKindIconComponent = ({
  serviceKind,
  ...props
}: SVGProps<SVGSVGElement> &
  HomebuyerPathIcon & { serviceKind: ServiceKind }) => {
  const ServiceKindIcon = homebuyerPathIconFromServiceKind(serviceKind)
  return <ServiceKindIcon {...props} />
}

export const ServiceKindIcon = ServiceKindIconComponent

export default ServiceKindIcon
